import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { MdMenu, MdClose } from "react-icons/md";
import classNames from "classnames";
import styles from "./Menu.module.scss";

const Menu = ({ light }) => {
  const activeStyle = {
    borderBottom: "2px solid var(--red-cs)"
  };

  return (
    <React.Fragment>
      <div
        className={classNames(styles.menuContainer, {
          [styles.light]: light
        })}
      >
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-12">
              <div className="d-flex align-items-center h-100">
                <div className={styles.title}>
                  <Link to="/home">Accessing Campscapes</Link>
                </div>
                <div className={styles.menuButtons}>
                  <NavLink activeStyle={activeStyle} to="/camps">
                    Camps
                  </NavLink>
                </div>
                <div className={styles.menuButtons}>
                  <NavLink activeStyle={activeStyle} to="/icons">
                    Icons
                  </NavLink>
                </div>
                <div className={styles.menuButtons}>
                  <NavLink activeStyle={activeStyle} to="/themes">
                    Themes
                  </NavLink>
                </div>
                <div
                  className={`ml-auto ${styles.menuButtons} ${styles.about}`}
                >
                  <NavLink
                    activeStyle={activeStyle}
                    to="/about"
                    className={styles.secondary}
                  >
                    About
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Menu;
