import React from "react";
import { Document, Page, Text, StyleSheet } from "@react-pdf/renderer";
import get from "lodash/get";
import stripHtml from "string-strip-html";

const StoryPDF = ({ title, tags, creator, pages }) => {
  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35
    },
    title: {
      fontSize: 24,
      textAlign: "left",
      margin: 12
    },
    author: {
      fontSize: 12,
      textAlign: "left",
      margin: 12
    },
    tags: {
      fontSize: 12,
      textAlign: "left",
      marginBottom: 40,
      marginLeft: 12,
      marginRight: 12
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: "left",
      fontFamily: "Times-Roman"
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey"
    }
  });
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title}>{title}</Text>
        {creator && creator.length > 0 && (
          <Text style={styles.author}>Author(s): {creator.join(", ")}</Text>
        )}

        {tags && tags.length > 0 && (
          <Text style={styles.tags}>Tag(s): {tags.join(", ")}</Text>
        )}
        {pages.map((page, i) => {
          const text = stripHtml(get(page, "page_blocks[0].text", null));
          return (
            <Text style={styles.text} key={i}>
              {text}
            </Text>
          );
        })}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default StoryPDF;
